import _ from 'lodash';

/**
 * 必填项的校验规则  因为加入了自定义规则，所以前边必填的*不会显示
 * @param message 提示信息
 * @param type 必填项的类型,默认为字符串
 */
export function isRequired(message = '必填项', type = 'string') {
  if (type === 'string') {
    // 字符串需要判断空格错误
    const requiredRule = (rule, value, callback) => {
      const v = _.trim(value);
      if (v === '') {
        callback(message);
      } else {
        callback();
      }
    };
    return {
      required: true,
      validator: requiredRule,
    };
  }
  return {
    required: true,
    // whitespace: true, // TODO element 组件好像没有生效
    message,
    type,
  };
}

/**
 * 异步校验规则
 */
export const validator = (fn, msg, args) => (rule, value, callback) => {
  fn({ value, ...args }).then(({ data }) => {
    if (data) {
      callback(_.isFunction(msg) ? msg(value) : msg);
    } else {
      callback();
    }
  });
};

/**
 * 异步校验规则 如果 不 存在触msg
 */
export const validatorNoExist = (fn, msg, args) => (rule, value, callback) => {
  fn({ value, ...args }).then(({ data }) => {
    if (!data) {
      callback(_.isFunction(msg) ? msg(value) : msg);
    } else {
      callback();
    }
  });
};
