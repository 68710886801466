<template>
  <div class="szzy">
    <!-- 教参 -->
    <heads />
    <div id="book">
      <div class="book-title">下载数字资源</div>
      <div class="book-desc">输入激活码下载所需资源</div>
      <div class="code-desc">每个激活码能够有效激活五次</div>
      <div class="code-desc">每个下载地址均是三天内有效</div>
      <div class="code-style">
        <el-form ref="form"
          :model="form"
          :rules="formRules">
          <el-form-item prop="code">
            <el-input v-model="form.code"
              placeholder="请输入激活码">
              <template slot="append"><span style="display:inline-block;width:120px;height: 50px;"
                  @click="getBook">获取下载地址</span></template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="books">
          <img :src="bookicon"
            class="book-icon">
          <div class="books-version">
            <div class="books-name">下载地址(三天内有效)：</div>
            <div class="books-info">
              <a v-if="!downfull"
                :href="resourseUrl"
                style="display: inline-block;"
                download>
                <div class="resourseUrl">{{ resourseUrl }}</div>
              </a>
              <div v-if="downfull"
                class="resourseUrl">{{ message }}</div>
              <div v-if="!downfull"
                class="downNum">
                剩余有效激活次数：
                <span style="color: #FF4949;">{{ 5 - num }}</span>
                <span>/5</span>
              </div>
            </div>
          </div>
          <a v-if="!downfull"
            :href="resourseUrl"
            download
            style="color:#fff;">
            <el-button type="primary"
              class="downClick">下载</el-button>
          </a>
        </div>
      </div>
      <div class="books-img" />
      <div style="height: 80px;" />
    </div>
    <foots />
  </div>
</template>
<script>
import _ from 'lodash';
import { isRequired } from 'util/inputRules';
import heads from './common/head';
import foots from './common/foot';
import bookicon from '../assets/login/bookicon.svg';
import { getResource } from './api.js';

export default {
  components: {
    heads,
    foots,
  },
  data() {
    return {
      bookicon,
      bookData: {},
      form: {},
      formRules: {
        code: [
          {
            ...isRequired('请输入激活码'),
            trigger: 'blur',
          },
        ],
      },
      // 下载资源地址
      resourseUrl: '',
      // 已下载次数
      num: 0,
      // 下载次数已满
      downfull: false,
      // 报错文案
      message: '',
    };
  },
  created() { },
  methods: {
    /* 获取教参 */
    getBook() {
      this.$refs.form.validate(valid => {
        if (valid) {
          getResource({ code: _.trim(this.form.code, ' ') }).then(({ data, err }) => {
            if (!err) {
              const { status = 0, num = 0, message = '获取失败', url = '' } = data;
              if (status === 404) {
                this.message = message;
                this.downfull = true;
                const book = document.querySelector('.books');
                book.style.display = 'block';
              } else if (status === 501) {
                this.message = message;
                this.downfull = true;
                const book = document.querySelector('.books');
                book.style.display = 'block';
              } else if (status === 200) {
                this.$message({
                  type: 'success',
                  message: '获取成功',
                });
                this.message = '';
                this.downfull = false;
                this.resourseUrl = url;
                this.num = num;
                const book = document.querySelector('.books');
                book.style.display = 'block';
              } else {
                this.$message({
                  type: 'error',
                  message,
                });
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style>
#book .el-input--small .el-input__inner {
  width: 470px;
  height: 50px !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
  float: left;
}
#book .el-input-group__append {
  float: left;
  color: #ffffff;
  width: 120px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
</style>

<style lang="less" scoped>
.szzy {
  #book {
    width: 100%;
    margin-top: 64px;
    padding-top: 172px;
    background: linear-gradient(180deg, rgba(253, 254, 255, 1) 0%, rgba(241, 248, 255, 1) 100%);
  }
  .book-title {
    width: 290px;
    font-size: 48px;
    color: #32325c;
    margin: 0 auto;
  }
  .book-desc {
    width: 264px;
    font-size: 24px;
    color: #32325c;
    margin: auto;
    margin-top: 4px;
  }
  .code-desc {
    width: 370px;
    text-align: center;
    font-size: 14px;
    color: #32325c;
    margin: auto;
    margin-top: 4px;
  }
  .code-style {
    width: 601px;
    height: 50px;
    margin: auto;
    margin-top: 18px;
  }
  .books-img {
    width: 805px;
    height: 394px;
    margin: auto;
    margin-top: 64px;
    background: url('../assets/login/books.svg') no-repeat 100% 100%;
  }
  .books {
    display: none;
    width: 590px;
    height: 150px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    margin-top: 24px;
    position: relative;
    z-index: 999999;
  }
  .books-version {
    margin-left: 24px;
    float: left;
    margin-top: 24px;
    width: 330px;
  }
  .books-name {
    font-size: 14px;
    width: 300px;
  }
  .books-info {
    margin-top: 4px;
    font-size: 14px;
    width: 300px;
  }
  .resourseUrl {
    color: #1890ff;
    margin-top: 4px;
    width: 300px;
    word-wrap: break-word;
  }
  .downNum {
    margin-top: 4px;
  }
  .book-icon {
    width: 96px;
    height: 96px;
    margin-left: 16px;
    margin-top: 30px;
    float: left;
  }
  .downClick {
    width: 88px;
    height: 32px;
    margin-left: 0px;
    margin-top: 60px;
  }
}
</style>
