var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "szzy" },
    [
      _c("heads"),
      _c("div", { attrs: { id: "book" } }, [
        _c("div", { staticClass: "book-title" }, [_vm._v("下载数字资源")]),
        _c("div", { staticClass: "book-desc" }, [
          _vm._v("输入激活码下载所需资源")
        ]),
        _c("div", { staticClass: "code-desc" }, [
          _vm._v("每个激活码能够有效激活五次")
        ]),
        _c("div", { staticClass: "code-desc" }, [
          _vm._v("每个下载地址均是三天内有效")
        ]),
        _c(
          "div",
          { staticClass: "code-style" },
          [
            _c(
              "el-form",
              { ref: "form", attrs: { model: _vm.form, rules: _vm.formRules } },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "code" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入激活码" },
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      },
                      [
                        _c("template", { slot: "append" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "120px",
                                height: "50px"
                              },
                              on: { click: _vm.getBook }
                            },
                            [_vm._v("获取下载地址")]
                          )
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "books" }, [
              _c("img", {
                staticClass: "book-icon",
                attrs: { src: _vm.bookicon }
              }),
              _c("div", { staticClass: "books-version" }, [
                _c("div", { staticClass: "books-name" }, [
                  _vm._v("下载地址(三天内有效)：")
                ]),
                _c("div", { staticClass: "books-info" }, [
                  !_vm.downfull
                    ? _c(
                        "a",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: { href: _vm.resourseUrl, download: "" }
                        },
                        [
                          _c("div", { staticClass: "resourseUrl" }, [
                            _vm._v(_vm._s(_vm.resourseUrl))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.downfull
                    ? _c("div", { staticClass: "resourseUrl" }, [
                        _vm._v(_vm._s(_vm.message))
                      ])
                    : _vm._e(),
                  !_vm.downfull
                    ? _c("div", { staticClass: "downNum" }, [
                        _vm._v(
                          "\n              剩余有效激活次数：\n              "
                        ),
                        _c("span", { staticStyle: { color: "#FF4949" } }, [
                          _vm._v(_vm._s(5 - _vm.num))
                        ]),
                        _c("span", [_vm._v("/5")])
                      ])
                    : _vm._e()
                ])
              ]),
              !_vm.downfull
                ? _c(
                    "a",
                    {
                      staticStyle: { color: "#fff" },
                      attrs: { href: _vm.resourseUrl, download: "" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "downClick",
                          attrs: { type: "primary" }
                        },
                        [_vm._v("下载")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        ),
        _c("div", { staticClass: "books-img" }),
        _c("div", { staticStyle: { height: "80px" } })
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }